import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
	en: {
		translation: {
			Footer: {
				terms: {
					url: "https://cyclops.nl/algemene-voorwaarden/",
					label: "Algemene voorwaarden",
				},

				privacy: {
					url: "https://cyclops.nl/privacy-disclaimer/",
					label: "Privacy Disclaimer",
				},
			},

			NotFoundScreen: {
				title: "Oeps, de pagina die u zoekt is niet gevonden",
				description: "Probeer het opnieuw.",
			},

			ResetPasswordScreen: {
				success: {
					title: "Wachtwoord opnieuw instellen",
					description:
						"Vul hieronder je nieuwe wachtwoord in waarmee je wilt inloggen.",
					button: "Wachtwoord herstellen",
					password: "Wachtwoord",
					password_confirmation: "Herhaal wachtwoord",

					validate: "Wachtwoorden komen niet overeen met elkaar",
					required: "Niet alle verplichte velden zijn ingevuld",
					pattern:
						"Je wachtwoord moet aan het volgende voldoen: één kleine letter, één hoofdletter, één cijfer, één speciaal teken en een lengte van 8 - 24 karakters",
				},

				complete: {
					title: "Je wachtwoord is opnieuw ingesteld!",
					description: "Je kunt nu inloggen met je nieuwe wachtwoord.",
				},

				error: {
					title: "Je wachtwoord kon niet worden hersteld!",
					description:
						"Er is iets fout gegaan. Probeer het opnieuw of vraag een nieuwe wachtwoordherstellink aan.",
				},
			},

			RegistrationScreen: {
				success: {
					title: "Je registratie voltooien!",
					description:
						"Zodra de registratie is voltooid kan je met dit account inloggen in de app.",
				},

				form: {
					button: "Aanmelden",
					password: "Wachtwoord",
					password_confirmation: "Herhaal wachtwoord",

					validate: "Wachtwoorden komen niet overeen met elkaar",
					required: "Niet alle verplichte velden zijn ingevuld",
					pattern:
						"Je wachtwoord moet aan het volgende voldoen: één kleine letter, één hoofdletter, één cijfer, één speciaal teken en een lengte van 8 - 24 karakters",
				},

				complete: {
					title: "Je account is succesvol aangemaakt!",
					description: "Met dit account kun je nu inloggen in de app.",
				},

				error: {
					title: "Je account kon niet worden aangemaakt!",
					description: "Probeer het opnieuw.",
				},
			},
		},
	},
};

i18n
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		resources,
		lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
		// you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
		// if you're using a language detector, do not define the lng option

		interpolation: {
			escapeValue: false, // react already safes from xss
		},
	});

export default i18n;
