import { API } from "api";
import { BaseService } from "../BaseService";
import {
	AuthResetRequest,
	AuthResetValidateRequest,
	AuthResetValidateResponse,
} from "./AuthService.props";

export class AuthService extends BaseService {
	validate = async (params: AuthResetValidateRequest) => {
		const response = await this.post<AuthResetValidateResponse>(
			"/reset/validate",
			params
		);

		return response.data;
	};

	reset = async (params: AuthResetRequest) => {
		await this.post<void>("/reset", params);
	};
}

export const authService = new AuthService(API, "/auth");
