import { forwardRef } from "react";
import { UseFormRegisterReturn } from "react-hook-form";
import styles from "./Input.module.scss";

interface Props extends UseFormRegisterReturn {
	label: string;
	type: "text" | "password";
	id: string;
	placeholder?: string;
}

export const Input = forwardRef(({ id, label, ...props }: Props, ref) => {
	return (
		<div>
			<label className={styles.Label} htmlFor={id}>
				{label}
			</label>

			<input className={styles.Input} {...props} ref={ref as any} />
		</div>
	);
});
