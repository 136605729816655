import { Page, Paragraph, Title } from "components";
import { useTranslation } from "react-i18next";

export const NotFoundScreen = () => {
	const { t } = useTranslation();

	return (
		<Page>
			<Title>{t("NotFoundScreen.title")}</Title>
			<Paragraph>{t("NotFoundScreen.description")}</Paragraph>
		</Page>
	);
};
