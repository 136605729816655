import { AxiosInstance, AxiosRequestConfig } from 'axios';

export class BaseService {
  protected endpoint: string | undefined;

  constructor(private axios: AxiosInstance, endpoint?: string) {
    this.axios = axios;
    this.endpoint = endpoint;
  }

  public getAxiosInstance = () => {
    return this.axios;
  };

  public getEndpoint = () => {
    return this.endpoint;
  };

  protected get = async <T>(url?: string, data?: any, config?: AxiosRequestConfig) => {
    const args = {
      ...config,
      params: {
        ...(config?.params || {}),
        data
      }
    };

    return this.axios.get<T>(`${this.endpoint}${url ?? ''}`, args);
  };

  protected delete = <T>(url: string, data?: any, config?: AxiosRequestConfig) => {
    const args = {
      ...config,
      params: {
        ...(config?.params || {}),
        data
      }
    };

    return this.axios.delete<T>(`${this.endpoint}${url}`, args);
  };

  protected post = <T>(url: string, data?: any, config?: AxiosRequestConfig) => {
    return this.axios.post<T>(`${this.endpoint}${url}`, data || {}, config);
  };

  protected put = <T>(url: string, data?: any, config?: AxiosRequestConfig) => {
    return this.axios.put<T>(`${this.endpoint}${url}`, data || {}, config);
  };
}
