import styles from "./Header.module.scss";
import defaultLogo from "./../../assets/logo.png";

interface Props {
	logo?: string;
}

export const Header = ({ logo }: Props) => {
	return (
		<header className={styles.Header}>
			<img className={styles.Logo} src={logo || defaultLogo} alt="" />
		</header>
	);
};
