import { API } from "api";
import { BaseService } from "../BaseService";
import {
	RegistrationCompleteRequest,
	RegistrationValidateRequest,
	RegistrationValidateResponse,
} from "./RegistrationService.props";

export class RegistrationService extends BaseService {
	validate = async (params: RegistrationValidateRequest) => {
		const response = await this.post<RegistrationValidateResponse>(
			"/validate",
			params
		);

		return response.data;
	};

	complete = async (params: RegistrationCompleteRequest) => {
		await this.post<void>("/complete", params);
	};
}

export const registraionService = new RegistrationService(API, "/registration");
