import { Button, Page, Paragraph, Title, Input, Error } from "components";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { authService, AuthResetValidateResponse } from "services/Auth";

enum Status {
	SUCCESS = "SUCCESS",
	LOADING = "LOADING",
	ERROR = "ERROR",
	COMPLETE = "COMPLETE",
}

export const ResetPasswordScreen = () => {
	const { t } = useTranslation();
	const [company, setCompany] = useState<AuthResetValidateResponse>();
	const [status, setStatus] = useState<Status>(Status.LOADING);
	const query = useLocation();

	const {
		register,
		handleSubmit,
		watch,
		formState: { errors },
	} = useForm();

	useEffect(() => {
		(async () => {
			try {
				const params = new URLSearchParams(query.search);

				const response = await authService.validate({
					email: String(params.get("email")),
					token: String(params.get("token")),
				});

				document.title = response.title;

				setCompany(response);
				setStatus(Status.SUCCESS);
			} catch (e) {
				setStatus(Status.ERROR);
			}
		})();
	}, [query.search]);

	const password = useRef({});
	password.current = watch("password", "");

	const error = errors[Object.keys(errors)[0]];

	const onSubmit = async (values: any) => {
		try {
			const params = new URLSearchParams(query.search);

			await authService.reset({
				token: params.get("token"),
				email: params.get("email"),
				...values,
			});

			setStatus(Status.COMPLETE);
		} catch (e) {
			setStatus(Status.ERROR);
		}
	};

	return (
		<Page logo={company?.logo}>
			{status === Status.LOADING && <Title>...</Title>}

			{status === Status.SUCCESS && (
				<>
					<Title>{t("ResetPasswordScreen.success.title")}</Title>
					<Paragraph>{t("ResetPasswordScreen.success.description")}</Paragraph>

					<form onSubmit={handleSubmit(onSubmit)}>
						<Input
							id="password"
							label={t("ResetPasswordScreen.success.password")}
							placeholder={t("ResetPasswordScreen.success.password")}
							type="password"
							{...register("password", {
								required: true,
								pattern:
									/(?=^.{8,24}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_\-+}{&;:;'?/&.,<>])(?!.*\s).*$/g,
							})}
						/>

						<Input
							id="passwordConfirmation"
							label={t("ResetPasswordScreen.success.password_confirmation")}
							placeholder={t(
								"ResetPasswordScreen.success.password_confirmation"
							)}
							type="password"
							{...register("passwordConfirmation", {
								validate: (value) => value === password.current,
							})}
						/>

						{error && error.type === "required" && (
							<Error>{t("ResetPasswordScreen.success.required")}</Error>
						)}

						{error && error.type === "pattern" && (
							<Error>{t("ResetPasswordScreen.success.pattern")}</Error>
						)}

						{error && error.type === "validate" && (
							<Error>{t("ResetPasswordScreen.success.validate")}</Error>
						)}

						<Button type="submit">
							{t("ResetPasswordScreen.success.button")}
						</Button>
					</form>
				</>
			)}

			{status === Status.COMPLETE && (
				<>
					<Title>{t("ResetPasswordScreen.complete.title")}</Title>
					<Paragraph>{t("ResetPasswordScreen.complete.description")}</Paragraph>
				</>
			)}

			{status === Status.ERROR && (
				<>
					<Title>{t("ResetPasswordScreen.error.title")}</Title>
					<Paragraph>{t("ResetPasswordScreen.error.description")}</Paragraph>
				</>
			)}
		</Page>
	);
};
