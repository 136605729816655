import { BrowserRouter, Switch, Route } from "react-router-dom";

import {
	ResetPasswordScreen,
	NotFoundScreen,
	RegistrationScreen,
} from "./screens";

import "./i18n";
import "./App.scss";
import { useEffect } from "react";

function App() {
	useEffect(() => {
		document.title = "Cyclops";

		document.documentElement.style.setProperty("--color-primary", "#21345f");
		document.documentElement.style.setProperty("--color-secondary", "#F9E701");
		document.documentElement.style.setProperty("--color-font", "#15260D");
	}, []);

	return (
		<BrowserRouter>
			<Switch>
				<Route path="/account-activeren" component={RegistrationScreen} />
				<Route path="/wachtwoord-herstellen" component={ResetPasswordScreen} />

				<Route path="*" component={NotFoundScreen} />
			</Switch>
		</BrowserRouter>
	);
}

export default App;
