import styles from "./Button.module.scss";

interface Props {
  children: any;
  type?: "button" | "submit" | "reset" | undefined;
  onPress?: () => void;
}

export const Button = ({ children, onPress, type = "button" }: Props) => {
  return (
    <button onClick={onPress} type={type} className={styles.Button}>
      {children}
    </button>
  );
};
